import Vue from 'vue'
import Vuex from 'vuex'
import get from 'lodash/get';
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loaded: false,
        user: {},
        ticket: null,
        schedule: null,
        schedule_tomorrow: null,
        ego: {},
        group: {},

        token: localStorage.getItem('token'),

        plans: [],
        vouchers: [],
        termsOfUse: '',

        isOffline: false,

        error: null,

        days: {},
    },

    getters: {
        loaded(state) {
            return state.loaded;
        },
        requiresDesk(state) {
            return state.user.desk_required !== 'none';
        },
        groupRequiresSubscription(state, getters) {
            if (!state.loaded) {
                return false;
            }

            return getters.requiresDesk
                && state.group.subscription_required;
        },
        hasSubscription(state, getters) {
            if (!getters.groupRequiresSubscription) {
                return true;
            }

            return state.user.has_subscription;
        },
        subscriptions(state) {
            if (state.user.subscriptions === undefined) {
                return [];
            }
            return state.user.subscriptions;
        },
        hasSlots(state) {
            return get(state.group, 'slots', []).length > 1;
        },
        days(state) {
            return get(state.days, 'days', []).concat(get(state.days, 'payed_days', []));
        },
        isFcfsGroup(state) {
            return state.group.allocation_mode === "fcfs";
        },
        isAlgorithmGroup(state) {
            return state.group.allocation_mode === "algorithm";
        },
        loggedIn(state) {
            return state.token !== null;
        },
    },

    mutations: {
        login(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        logout(state) {
            state.token = null;
            localStorage.removeItem('token');
            router.replace('/logout');
        },
    },

    actions: {
        load({state, dispatch}) {
            if (state.loaded) {
                return;
            }
            dispatch('reload');
        },
        reload({state, dispatch}) {
            if (localStorage.getItem('token') === null) {
                return;
            }
            state.loaded = false;
            dispatch('reloadEgo');
            dispatch('loadDay');
        },
        reloadEgo({state}) {
            Vue.prototype.$http.get('ego')
                .then(function (response) {
                    state.isOffline = false;
                    state.user = response.data.user;
                    state.ticket = response.data.ticket;
                    state.schedule = response.data.schedule;
                    state.schedule_tomorrow = response.data.schedule_tomorrow;
                    state.ego = response.data;
                    state.group = response.data.group;
                    localStorage.setItem('email', response.data.user.email);

                    state.loaded = true;
                })
                .catch(function (error) {
                    state.loaded = true;
                    state.isOffline = true;
                    state.error = error;
                });
        },
        loadDay({state}) {
            Vue.prototype.$http.get('days')
                .then((response) => {
                    state.days = response.data;
                })
        },
        resetLotteryRunStatus({state}) {
            state.ego.lottery_run_status = {};
        },
        handleErrorResponse({state}, error) {
            if (error.response === undefined) {
                return;
            }
            if (error.response.status === 200) {
                return;
            }
            state.error = error;
        },
        dayPush({state}, day) {
            state.days.days.push(day);
        },
        dayDelete({state}, day) {
            state.days.days.splice(state.days.days.findIndex(date => date.day === day.day), 1);
        },
    },
})
