<template>
    <div>
        <div class="fixed inset-0 overflow-auto py-8"
             @click.self="$emit('close')"
             @keyup.esc="$emit('close')"
             style="background-color: rgba(0, 0, 0, 0.5); z-index:150">
            <div class="rounded mx-auto flex-col flex"
                 :class="{'lg:w-1/2 mx-4': !large, 'mx-8': large}">
                <div class="">
                    <div class="rounded-t-lg bg-white text-black items-baseline flex justify-between p-2 sm:p-4">
                        <div class="font-bold">
                            <slot name="header"></slot>
                        </div>

                        <div class="pt-2 pr-2 sm:block sm:pt-4 sm:pr-4">
                            <button @click="$emit('close')" type="button"
                                    class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                                    aria-label="Close">
                                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="rounded-b-lg bg-white p-2" :class="{'lg:p-8': !large}">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        large: {
            type: Boolean,
            default: false,
        },
    },

    created() {
        document.body.className = "bg-gray-200 fixed overflow-none";
    },

    destroyed() {
        document.body.className = "bg-gray-200";
    }
}
</script>
