<template>
    <card-full-invisible>
        <template slot="header">Seminarräume</template>

        <loading v-show="loading"></loading>

        <tailwind-list>
            <tailwind-list-item
                @click="open(booking)"
                :key="booking.id"
                v-for="booking in bookings">
                {{ booking.start | datetime }} - {{ booking.end | time }}
                <template slot="secondary">Seminarraum {{ booking.conference_room.name }}</template>
            </tailwind-list-item>
        </tailwind-list>

        <p class="p-4 text-gray-700" v-show="bookings.length === 0">Keine Buchungen gefunden.</p>

        <portal to="modals" v-if="showModal">
            <modal @close="showModal = false">
                <template slot="header">Buchungsdetails</template>

                <div class="flex justify-end">
                    <confirm-dialog @confirmed="remove(item)">
                        <button-delete><i class="fas fa-trash mr-2"></i> Buchung löschen</button-delete>
                        <template slot="header">Buchung löschen</template>
                        <template slot="question">
                            Soll die Buchung für
                            <span class="font-bold">{{ item.start | datetime }}</span>
                            wirklich gelöscht werden?
                        </template>
                        <template slot="confirm">Buchung löschen</template>
                    </confirm-dialog>
                </div>

                <p class="mb-2">Seminarraum: {{ item.conference_room.name }}</p>

                <p class="mb-2">Zeitraum: {{ item.start | datetime }} - {{ item.end | time }}</p>

                <p class="mb-2">Personen: {{ item.conference_room.persons }}</p>

                <button class="btn-primary" @click="createAppointment" v-show="isAppointment">
                    Details in den Kalender übertragen
                </button>
                <button class="btn-primary" @click="createMessage" v-show="isMessage">
                    Details in Mail übertragen
                </button>
            </modal>
        </portal>
    </card-full-invisible>
</template>

<script>
import ConfirmDialog from "./UI/ConfirmDialog";
import ButtonDelete from "./UI/ButtonDelete";
import CardFullInvisible from "./UI/CardFullInvisible";
import TailwindList from "@/components/UI/TailwindList";
import TailwindListItem from "@/components/UI/TailwindListItem";
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";

export default {
    name: "ConferenceRooms",
    components: {
        Loading,
        Modal,
        TailwindListItem,
        TailwindList, CardFullInvisible, ButtonDelete, ConfirmDialog,
    },

    data: () => ({
        loading: true,
        rooms: [],
        bookings: [],
        item: null,
        showModal: false,
    }),

    filters: {
        time(val) {
            return val.substr(11, 5) + " Uhr";
        },
        datetime(val) {
            let [y, m, d] = val.substr(0, 10).split('-');
            let time = val.substr(11, 5);
            return `${d}.${m}.${y} ${time}`;
        }
    },

    mounted() {
        this.load();
    },

    computed: {
        isAppointment() {
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType === 'appointment';
        },
        isMessage() {
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType === 'message';
        },
    },

    methods: {
        createAppointment() {
            // eslint-disable-next-line no-undef
            if (Office.context.mailbox.displayNewAppointmentForm === undefined) {
                // try to access the appointment form directly!
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.start.setAsync(new Date(this.item.start));
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.end.setAsync(new Date(this.item.end));
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.location.setAsync(this.item.conference_room.name);
            }
        },
        createMessage() {
            const start = this.$options.filters.datetime(this.item.start);
            const end = this.$options.filters.time(this.item.end);
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.body.prependAsync(
                `Seminarraum: ${this.item.conference_room.name}
                Zeitraum: ${start} - ${end}
                Personen: ${this.item.conference_room.persons}`
            );
        },
        open(booking) {
            this.item = booking;
            this.showModal = true;
        },
        load() {
            this.loading = true;
            this.bookings = [];
            this.rooms = [];
            this.$http
                .get('/conference_rooms')
                .then(result => {
                    this.rooms = result.data.data;
                    this.bookings = result.data.bookings;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.dispatch('handleErrorResponse', error);
                })
        },
        remove(item) {
            this.loading = true;
            this.$http
                .post('/conference_room_bookings/' + item.id, {
                    _method: 'delete',
                })
                .then(() => {
                    this.load();
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.dispatch('handleErrorResponse', error);
                })
        },
    }
}
</script>

<style scoped>

</style>
