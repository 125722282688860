<template>
    <div>
        <a @click="check">
            <slot>IM A BUTTON</slot>
        </a>

        <portal to="dialog" v-if="showModal">
            <modal @close="showModal = false">

                <template slot="header">
                    <slot name="header">slot::header</slot>
                </template>

                <div class="mb-4">
                    <slot name="question">Möchtest du wirklich?</slot>
                </div>

                <button-primary @click="confirm()">
                    <slot name="confirm">CONFIRM</slot>
                </button-primary>

            </modal>
        </portal>
    </div>
</template>

<script>
    import ButtonPrimary from "./ButtonPrimary";
    import Modal from "../Modal";

    export default {
        name: "ConfirmDialog",
        components: {ButtonPrimary, Modal},
        props: {
            needConfirmation: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            showModal: false,
        }),

        methods: {
            check() {
                if (!this.needConfirmation) {
                    return this.confirm();
                }

                this.showModal = true;
            },
            confirm() {
                this.showModal = false;
                this.$emit('confirmed');
            },
        },
    }
</script>

<style scoped>

</style>
