<template>
    <li>
        <a @click="$emit('click')" class="block hover:bg-gray-50 px-4 py-4">
            <div class="flex items-center py-4">
                <div class="min-w-0 flex-1 flex items-center">
                    <div class="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                            <p class="text-sm font-medium text-indigo-600 truncate">
                                <slot></slot>
                            </p>
                        </div>
                        <div>
                            <div>
                                <p class="flex items-center text-sm text-gray-500">
                                    <slot name="secondary"></slot>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-sm" v-show="hasAction">
                    <i class="fas fa-chevron-right text-gray-400 text-sm"></i>
                </div>
            </div>
        </a>
    </li>
</template>

<script>
export default {
    name: "TailwindListItem",

    props: {
        hasAction: {
            type: Boolean,
            default: true,
        }
    }
}
</script>

<style scoped>

</style>