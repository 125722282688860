<template>
    <div id="app">
        <!--<div id="nav">
            <router-link to="/">Home</router-link>
            |
            <router-link to="/about">About</router-link>
        </div>-->
        <nav class="bg-gray-100 h-16 flex items-center p-4" v-show="requiresNavigation">
            <router-link to="/">
                <i class="fas fa-chevron-left mr-2"></i> Zum Menü
            </router-link>
        </nav>
        <div class="p-4">
            <router-view v-if="!loading"></router-view>
            <loading v-show="loading"></loading>
        </div>

        <error-response></error-response>

        <portal-target name="modals"></portal-target>
        <portal-target name="dialog"></portal-target>
        <portal-target name="terms"></portal-target>
    </div>
</template>

<script>
import ErrorResponse from "@/components/ErrorResponse";
import Loading from "@/components/Loading";
export default {
    components: {Loading, ErrorResponse},
    data: () => ({
        loading: true,
    }),

    created() {
        this.sso();
    },

    computed: {
        requiresNavigation() {
            return !['Home', 'Login', 'Logout'].includes(this.$route.name);
        }
    },

    methods: {
        async sso() {
            this.loading = true;
            if (this.$store.getters.loggedIn) {
                this.loading = false;
                return;
            }
            try {
                // eslint-disable-next-line no-undef
                let bootstrapToken = await OfficeRuntime.auth.getAccessToken();

                // The /api/DoSomething controller will make the token exchange and use the
                // access token it gets back to make the call to MS Graph.

                //getData("/api/DoSomething", bootstrapToken);
                //this.loginSSO(bootstrapToken);
                const b = bootstrapToken.split('.')[1];

                console.log(bootstrapToken);

                console.log(JSON.parse(atob(bootstrapToken.split('.')[0])))

                console.log(JSON.parse(atob(bootstrapToken.split('.')[1])))

                if (!this.email) {
                    this.email = JSON.parse(atob(b))['unique_name'];
                }

                this.loginViaSSO(bootstrapToken);

            } catch (exception) {
                this.loading = false;
                console.log(exception);
                if (exception.code === 13003) {
                    // SSO is not supported for domain user accounts, only
                    // Microsoft 365 Education or work account, or a Microsoft account.
                } else {
                    // Handle error
                }
            }
        },
        loginViaSSO(token) {
            this.$http.post('https://api.officeefficient.de/json/ms-office', {
                token: token,
            }).then((response) => {
                this.loading = false;
                this.$store.commit('login', response.data.token);
                this.$router.replace('/');
            }).catch(() => {
                this.loading = false;
            });
        },
    }

}
</script>