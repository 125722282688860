<template>
    <div>
        <portal to="modals" v-if="hasError">
            <modal @close="deleteError">
                <template slot="header">Fehler</template>

                <div v-show="status === 401">
                    Bitte anmelden.

                    <router-link to="login">Login</router-link>
                </div>

                <div v-show="status === 403">
                    <p>Nicht erlaubte Benutzung</p>

                    <p>{{ error.data.message }}</p>
                </div>

                <div v-show="status === 404">
                    <p>Seite nicht gefunden</p>

                    <p>{{ error.data.message }}</p>
                </div>

                <div v-show="status === 429">
                    Zu viele Anfragen. Bitte warte eine Minute.
                </div>

                <div v-show="status === 422">
                    <p>Die eingegebenen Daten waren ungültig.</p>

                    <p v-for="(errors, field) in error.data.errors" :key="field" class="mt-2">
                        {{ field }}: <span v-for="error in errors" :key="error">{{ error }}</span>
                    </p>
                </div>

                <div v-show="status === 500">
                    Wir arbeiten gerade an der Verbesserung deiner App - versuch es einfach später noch einmal.
                </div>

                <div v-show="isUnknown">
                    <h1>{{ error }}</h1>
                    Ein Fehler ist aufgetreten.

                    {{ error }}
                </div>

            </modal>
        </portal>
    </div>
</template>

<script>
    import Modal from "@/components/Modal";
    export default {
        name: "ErrorResponse",
        components: {Modal},
        computed: {
            hasError() {
                return this.$store.state.error !== null;
            },
            error() {
                return this.$store.state.error.response;
            },
            status() {
                return this.error.status;
            },
            isUnknown() {
                return ![401, 403, 404, 422, 429, 500].includes(this.status);
            }
        },

        methods: {
            deleteError() {
                this.$store.state.error = null;
            },
        },
    }
</script>

<style scoped>

</style>
