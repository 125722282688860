<template>
    <card-full-invisible>
        <template slot="header">Schreibtische</template>

        <loading v-show="loading"></loading>

        <tailwind-list>
            <tailwind-list-item
                @click="open(booking)"
                :key="booking.id"
                v-for="booking in bookings">
                {{ booking.day | date }}
                <template slot="secondary">Schreibtisch {{ booking.desk.name }} {{ booking.details }}</template>
            </tailwind-list-item>
        </tailwind-list>

        <p class="p-4 text-gray-700" v-show="bookings.length === 0">Keine Buchungen gefunden.</p>

        <portal to="modals" v-if="showModal">
            <modal @close="showModal = false">
                <template slot="header">Buchungsdetails</template>

                <div class="flex justify-end">
                    <confirm-dialog @confirmed="remove(item)">
                        <button-delete><i class="fas fa-trash mr-2"></i> Buchung löschen</button-delete>
                        <template slot="header">Buchung löschen</template>
                        <template slot="question">
                            Soll die Buchung für
                            <span class="font-bold">{{ item.day | date }}</span>
                            wirklich gelöscht werden?
                        </template>
                        <template slot="confirm">Buchung löschen</template>
                    </confirm-dialog>
                </div>

                <p class="mb-2">Schreibtisch: {{ item.desk.name }}</p>

                <p class="mb-2">Datum: {{ item.day | date }}</p>

                <button class="btn-primary" @click="createAppointment" v-show="isAppointment">Details in den Kalender übertragen</button>

                <button class="btn-primary" @click="createMessage" v-show="isMessage">Details in Mail übertragen</button>
            </modal>
        </portal>
    </card-full-invisible>
</template>

<script>
import ConfirmDialog from "./UI/ConfirmDialog";
import ButtonDelete from "./UI/ButtonDelete";
import CardFullInvisible from "./UI/CardFullInvisible";
import TailwindList from "@/components/UI/TailwindList";
import TailwindListItem from "@/components/UI/TailwindListItem";
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";

export default {
    name: "Desks",
    components: {
        Loading,
        Modal,
        TailwindListItem,
        TailwindList,
        CardFullInvisible,
        ButtonDelete,
        ConfirmDialog,
    },

    data: () => ({
        loading: true,
        rooms: [],
        bookings: [],
        item: null,
        showModal: false,
    }),

    filters: {
        date(val) {
            let [y, m, d] = val.substr(0, 10).split('-');
            return `${d}.${m}.${y}`;
        }
    },

    mounted() {
        this.load();
        //this.init();
    },

    computed: {
        type() {
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType;
        },
        isAppointment() {
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType === 'appointment';
        },
        isMessage() {
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType === 'message';
        }
    },

    methods: {
        createAppointment() {
            // eslint-disable-next-line no-undef
            if (Office.context.mailbox.displayNewAppointmentForm === undefined) {
                // try to access the appointment form directly!
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.start.setAsync(new Date(this.item.day + ' 00:00:00'));
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.end.setAsync(new Date(this.item.day + ' 23:55:00'));
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.location.setAsync(this.item.desk.name);
                // part of preview: https://docs.microsoft.com/en-us/javascript/api/outlook/office.isalldayevent?view=outlook-js-preview
                // eslint-disable-next-line no-undef
                //Office.context.mailbox.item.isAllDayEvent.setAsync(true);
            }
        },
        createMessage() {
            const day = this.$options.filters.date(this.item.day);
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.body.prependAsync(
                `Schreibtisch: ${this.item.desk.name}, Datum: ${day} `
            );
        },
        open(booking) {
            this.showModal = true;
            this.item = booking;
        },
        load() {
            this.loading = true;
            this.bookings = [];
            this.rooms = [];
            this.$http
                .get('/schedules')
                .then(result => {
                    this.bookings = result.data.schedules.filter(item => {
                        return item.status !== 'released';
                    });
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.dispatch('handleErrorResponse', error);
                })
        },
        remove(item) {
            this.loading = true;
            this.$http
                .post('/schedules/' + item.id, {
                    _method: 'put',
                    action: 'decline',
                })
                .then(() => {
                    this.load();
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.dispatch('handleErrorResponse', error);
                })
        },
    }
}
</script>

<style scoped>

</style>
