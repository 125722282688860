import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            protected: true,
        },
    },
    {
        path: '/desk',
        component: () => import(/* webpackChunkName: "desk" */ '../views/DeskReservations.vue'),
        meta: {
            protected: true,
        },
    },
    {
        path: '/conference_room_bookings',
        component: () => import(/* webpackChunkName: "conf_booking" */ '../views/ConferenceRoomBooking.vue'),
        meta: {
            protected: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        meta: {
            protected: false,
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue'),
        meta: {
            protected: false,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {

    // route is not protected!
    if (!to.matched.some(record => record.meta.protected)) {
        return next();
    }

    // route is protected
    if (localStorage.getItem('token') === null) {
        return next('/login');
    }

    return next();
});


export default router
