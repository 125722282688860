<template>
    <span class="inline-flex rounded-md shadow-sm" @click="$emit('click')">
        <button type="button"
                class="inline-flex items-center px-4 py-2 border border-red-300 text-sm leading-5 font-medium rounded-md text-grey-700 bg-red-50 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
            <slot>BUTTON TEXT</slot>
        </button>
    </span>
</template>

<script>
export default {
    name: "ButtonDelete"
}
</script>

<style scoped>

</style>
