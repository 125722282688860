<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul class="divide-y divide-gray-200">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "TailwindList"
}
</script>

<style scoped>

</style>