<template>
    <div class="mt-8">
        <div class="px-3 py-5 sm:px-6 items-baseline flex justify-between">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                <slot name="header"></slot>
            </h3>
            <div><slot name="action"></slot></div>
        </div>
        <div class="">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CardFullInvisible"
    }
</script>

<style scoped>

</style>
