import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from "axios";
import PortalVue from "portal-vue";
import store from './store'
import './index.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false;

Vue.use(PortalVue);

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

Vue.prototype.$http.defaults.baseURL = 'https://api.officeefficient.de/api/v2/';

// eslint-disable-next-line no-undef
Office.onReady(() => {
    new Vue({
        router,
        store,
        render: h => h(App),
        created() {
            console.log("created");
            this.$http.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${this.$store.state.token}`;
                config.baseURL = 'https://api.officeefficient.de/api/v2/';

                return config;
            })
            this.$http.interceptors.response.use(undefined, (err) => {
                if (
                    err.response.status === 401
                    && this.$store.getters.loggedIn
                    && err.request.responseURL.endsWith('refresh')
                ) {
                    this.$store.commit('logout');
                }

                // try to refresh the token
                if (err.response.status === 401 && this.$store.getters.loggedIn) {
                    return this.$http.get('refresh').then((response) => {
                        if (response.data.token.length === 0) {
                            // could not refresh
                            this.$store.commit('logout');
                            return;
                        }
                        this.$store.commit('login', response.data.token);
                        err.response.config.baseURL = undefined; // baseURL is already present in url
                        err.response.config.headers.Authorization = `Bearer ${response.data.token}`;
                        return this.$http.request(err.response.config);
                    })
                }

                return Promise.reject(err);
            });
        },
    }).$mount('#app')
});


