<template>
    <div>
        <div class="flex flex-col items-center">
            <router-link to="desk" class="block">
                <button class="btn-primary">Schreibtisch buchen</button>
            </router-link>

            <router-link to="conference_room_bookings" class="block mt-4">
                <button class="btn-primary">Seminarrum buchen</button>
            </router-link>
        </div>

        <desks></desks>

        <conference-rooms></conference-rooms>



        <button class="btn-white mt-32" @click="logout">Abmelden</button>
    </div>
</template>

<script>
import ConferenceRooms from "@/components/ConferenceRooms";
import Desks from "@/components/Desks";
export default {
    name: 'Home',
    components: {Desks, ConferenceRooms},
    methods: {
        logout() {
            this.$store.commit('logout');
        }
    }
}
</script>
